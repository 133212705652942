import React, { Component } from 'react';
import '../Font/Font.css';
import './FormElements.css';




class SearchMoreButton extends Component {

  
  render() {

    
    return (
    
      <div 
            className= "montebello red skinBackground moreButton sticky">
            Search more items
      </div>

    )
 }  
}
  

export default SearchMoreButton