const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:47180381-34de-4c07-a68f-6a3bc963f54f",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_0Vt3rC3cJ",
    "aws_user_pools_web_client_id": "2r03khbbk1k1sp32tdedlnjgj2",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
};


export default awsmobile;
