import React, { Component } from "react";
import "../Font/Font.css";
import "./Tables.css";
import '../FormElements/FormElements.css';



class ImageHeader extends Component {
  

 

  render() {

    return (
        
     <th className="imageH tableAdjust" id= "image header">
        <span className="hidden">Image</span>
      </th>
    )
}
}

export default ImageHeader
